<template>
  <b-row>
    <b-col lg="6">
      <MainForm
        type="edit"
        v-on:submit="submit" />
    </b-col>
  </b-row>
</template>
<script>
import MainForm from './form'
import { mapState } from 'vuex'

export default {
  name: 'tagEdit',
  data() {
    return {}
  },
  computed: {
    ...mapState('tag', {
      tags: state => state.data,
    }),
  },
  created() {
    const vm = this
    vm.$store.dispatch('tag/FETCH_ONE', vm.$route.params.id)
  },
  methods: {
    submit() {
      const vm = this
      vm.$store.dispatch('tag/UPDATE', vm.$route.params.id).then((res) => {
        vm.$notification.success({
          message: 'Tag Updated',
          description: 'Successfully updated.',
        })
        vm.$router.push({ path: '/tags' })
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
